import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SwToast } from "../../../lib/SwAlert";
import config from "../../../lib/config";
import {Save_add_up_product_,ONUpdate,setMultipleImg,SelectDefaultImg} from "../../../store/productsSlice";
import { unSelect, Select } from "../../../store/CategoriesSlice";
import { SyncSuppliers } from "../../../store/supplierSlice";
import {setItemCampaignIds} from "../../../store/PublishCampaignSlice";
import Categories from "./components/Categories";
import "../../../assets/css/PointCashier/FormEdit/EditProduct.css";
import BGup from "../../../assets/images/bgim.png"; // Tell Webpack this JS file uses this image
import cache from "../../../lib/cache"; //local storage library
import ActiveCampaign from "./components/ActiveCampaign";
 
function EditProduct() {
  const { SuppliersList } = useSelector((state) => state.supplier);
   const { ItemCampIds } = useSelector((state) => state.publish_campaign);
  const Cash = cache.get("NewProduct");
  const {isPublished,isEdit,NewItem,SelectEditItem,MultipleImg,ErrorHandling,SuccessHandling,ErrorHandlingMsg,HandlingSuccessMsg  } = useSelector((state) => state.product);
  const { selectedCategory,categories } = useSelector((state) => state.category);
  const [ListCategoriesID, setListCategoriesID] = useState([]);
  const [Size, setSize] = useState([]);
  const [Category, setCategory] = useState(Cash ? Cash.DefaultCategory : 0);
  const [selectedImg, setSelectedImg] = useState(BGup);
  const [SelectedFile, setSelectedFile] = useState(null);
  const [SaveFormProduct, setSaveFormProduct] = useState(Cash ? cache.get("SaveFormProduct") : false );
  const dispatch = useDispatch();
  const SizNumber = [36, 38, 40, 42, 44, 46, 48, 50, 52];
  const SizXL = ["S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL"];
  const AcceptFileType = ".gif,.jpg,.jpeg,.png";
  const vL = Yup.string().required("The value is required !").min(5, "Must be 5 characters ").max(100, "Must be 100 characters or less").required("Required");
const validationSchemaNewProduct = Yup.object({title: vL,Description: vL,
  BuyPrice: Yup.number().typeError("The value Number ").integer("The value must Number ").required("Required"),
  price: Yup.number().typeError("The value Number ").integer("The value must Number ").required("Required"),
  productCode: Yup.string().max(250, "Must be 150 characters or less").required("Required"),
  Categories: Yup.mixed().required("Required"),Size: Yup.mixed().required("Required"),
  DefaultCategory: Yup.number().typeError("The value Number").integer("The value must Number").required("Required"),
  imgUrl: Yup.string().max(250, "Must be 150 characters or less").required("Required"),
  file: Yup.mixed().required("select  cover img"),
  defaultImg: Yup.number(),
  CountImg: Yup.mixed().required("select  product img"),
  Suppliers: Yup.number().required("select suppliers"),
});

const validationSchemaEditProduct = Yup.object({
  IndexId: Yup.number().typeError("The value must be a number"),
  id: Yup.number().typeError("The value Number").integer("The value must Number").required("Required"),
  title: vL,Description: vL,
  BuyPrice: Yup.number().typeError("The value Number ").integer("The value must Number ").required("Required"),
  price: Yup.number().typeError("The value Number ").integer("The value must Number ").required("Required"),
  productCode: Yup.string().max(250, "Must be 150 characters or less").required("Required"),
  Categories: Yup.mixed().required("Required"),Size: Yup.mixed().required("Required"),
  DefaultCategory: Yup.number().typeError("The value Number").integer("The value must Number").required("Required"),
  imgUrl: Yup.string().max(250, "Must be 150 characters or less").required("Required"),
  file: Yup.mixed(),  defaultImg: Yup.number(),CountImg: Yup.mixed(),
  Suppliers: Yup.number().required("select suppliers"),
});

  const formik = useFormik({
    initialValues: {id: 0,Suppliers:0,title: "",Description: "",BuyPrice: "",price: "",file: {},defaultImg: 0,Profit: 0,
    productCode: "",imgUrl: "",Categories: [],Size: "",DefaultCategory: "",IndexId: "",CountImg: null,ItemCampIds: ItemCampIds,
    },
    validationSchema:
      isEdit && NewItem
        ? validationSchemaNewProduct
        : validationSchemaEditProduct,
    onSubmit: (values, { resetForm }) => {
      if (!isEdit || NewItem === true) {
        handelSave(JSON.stringify(values, null, 2),resetForm);
      } else {
        handelSave(JSON.stringify(values, null, 2),resetForm); //save if update already
        resetForm({ values: "" });
      }
    },
  });

  const FMkv = formik.values;
  const FMKEr = formik.errors;

  useEffect(() => {
    if (FMkv.Profit >= 1) {
      const vProfit = parseInt(FMkv.BuyPrice) + parseInt(FMkv.Profit);
      formik.setFieldValue(
        "price",
        vProfit >= 1 ? vProfit : parseInt(FMkv.BuyPrice + vProfit)
      );
    }
    // eslint-disable-next-line
  }, [FMkv.Profit, FMkv.BuyPrice]);

  useEffect(() => {
    const st_ = setTimeout(() => {
      SaveFormProduct && cache.set("NewProduct", { ...FMkv, id: 0, _id: 0 });
    }, 100);
    return () => clearTimeout(st_);
  }, [FMkv, SaveFormProduct]);
  
  useEffect(() => {
    if (ItemCampIds.length > 0) {
      SaveFormProduct && cache.set("PublishComp", ItemCampIds);
      formik.setFieldValue("ItemCampIds",  ItemCampIds.length?ItemCampIds:[]);
      SaveFormProduct && cache.set("PublishComp", {ItemCampIds});
    }else if(ItemCampIds.length === 0){
      formik.setFieldValue("ItemCampIds", []);
      SaveFormProduct && cache.set("PublishComp", {ItemCampIds});
    }
     // eslint-disable-next-line
  }, [ItemCampIds, SaveFormProduct]);
  const handleSaveForm = () => {
    setSaveFormProduct(!SaveFormProduct);
    if (!SaveFormProduct) {
      cache.set("SaveFormProduct", !SaveFormProduct);
      cache.set("NewProduct", { ...FMkv, id: 0, _id: 0 });
      cache.set("PublishComp", {ItemCampIds});
       //save to local storage Product  item
    } else {
      cache.remove("SaveFormProduct");
      cache.remove("NewProduct");
      cache.remove("PublishComp");

    }
  };

  //check Errors cache.get("NewProduct")
  useEffect(() => {
    const st_ = setTimeout(() => {
      if (FMKEr.Suppliers) SwToast("warning", `Suppliers  `);
      if (FMKEr.title) SwToast("warning", `product Name   ${FMKEr.title}`);
      if (FMKEr.Description)        SwToast("warning", `product Description   ${FMKEr.Description}`);
      if (FMKEr.BuyPrice)        SwToast("warning", `product Buy Price   ${FMKEr.BuyPrice}`);
      if (FMKEr.price)        SwToast("warning", `product Sell Price   ${FMKEr.price}`);
      if (FMKEr.productCode)        SwToast("warning", `product Code   ${FMKEr.productCode}`);
      if (FMKEr.Categories)        SwToast("warning", `product Categories   ${FMKEr.Categories}`);
      if (FMKEr.Size)        SwToast("warning", `product Selected Size   ${FMKEr.Size}`);
      if (FMKEr.DefaultCategory)        SwToast("warning", `Default  Category   ${FMKEr.DefaultCategory}`);
      if (FMKEr.imgUrl)        SwToast("warning", `product Default Image   ${FMKEr.imgUrl}`);
      if (SuppliersList.length===1) {
        formik.setFieldValue("Suppliers", SuppliersList[0].SupplierID);
       }
    }, 100);
    return () => {
      clearTimeout(st_);
    };
    // eslint-disable-next-line
  }, [FMKEr]);

  useEffect(() => {
    if (SelectEditItem) {
       formik.setFieldValue("file", "");
      setSelectedFile([]);
      formik.setFieldValue("IndexId", SelectEditItem.IndexId);
      dispatch(setMultipleImg([]));
      if (!NewItem) {
        formik.setFieldValue("id", SelectEditItem.id);
      }
      async function setInitialValues() {
        await formik.setValues(SelectEditItem, false);
      }
      setInitialValues();
      setSize(SelectEditItem.Size);
      setCategory(SelectEditItem.DefaultCategory);
      if (ItemCampIds) {
        dispatch(setItemCampaignIds(ItemCampIds));
        formik.setFieldValue("ItemCampIds", ItemCampIds);
       }else{
        formik.setFieldValue("ItemCampIds", []);
       };
      if (!NewItem) {
        setSelectedImg(
          SelectEditItem.imgUrl.length
            ? config.get("URLs.product_ImgThumbnails") + SelectEditItem.imgUrl
            : BGup
        );
      }
      if (NewItem && !SelectedFile) {
        setSelectedImg(BGup);
      }
      dispatch(unSelect(ListCategoriesID));
      setCategory(SelectEditItem.DefaultCategory);
      if (SelectEditItem.Categories.length) {
        SelectEditItem.Categories.map((e, i) => {
          dispatch(Select(e));
          return false;
        });
        dispatch(Select(SelectEditItem.DefaultCategory));
      }
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, SelectEditItem,SuppliersList, NewItem]);

  useEffect(() => {
    setListCategoriesID(selectedCategory.map((Item, index) => Item._id));
    formik.setFieldValue("Categories", ListCategoriesID);
    formik.setFieldValue("Size", Size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory,categories, Size,ItemCampIds]);

  useEffect(() => {
    if (SuccessHandling === true) {
      SwToast("success", ` ${HandlingSuccessMsg}  🎉`);
      dispatch(ONUpdate());
    }
    if (ErrorHandling === true) {
      SwToast("error", `😡 ${ErrorHandlingMsg}`);
    }
  }, [
    ErrorHandling,
    SuccessHandling,
    ErrorHandlingMsg,
    HandlingSuccessMsg,
    dispatch,
  ]);

  const handelSave = (values,resetForm) => {
    const formData = new FormData();
    formData.append("productInfo", values);
    if (SelectedFile.length) {
      const ImgType = SelectedFile[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        for (let i = 0; i < SelectedFile.length; i++) {
          if (i !== 10) {
            formData.append(
              "File_data_" + [i],
              SelectedFile[i],
              SelectedFile[i].name
            );
          }
        }
      } else {
        SwToast("error", `😡 file type not include in accept `);
      }
    }
    dispatch(Save_add_up_product_(formData));
    dispatch(setMultipleImg([]));
    formData.delete(formData);
    // resetForm();
  };

  const isSelected = (SizBy) => {
    if (Size.length >= 1 && SizBy) {
      return Size.indexOf(SizBy) !== -1;
    }
  };
  const handleClick = (SizBy, TypeSize) => {
    let newSelected = [];
    const selectedIndex = Size.indexOf(SizBy);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(Size, SizBy);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(Size.slice(1));
    } else if (selectedIndex === Size.length - 1) {
      newSelected = newSelected.concat(Size.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        Size.slice(0, selectedIndex),
        Size.slice(selectedIndex + 1)
      );
    }
    setSize(newSelected);
    Clean(newSelected, TypeSize);
  };
  const Clean = (ar, TypeSize) => {
    if (TypeSize === "Num") {
      SizXL.map((Item) => {
        const index = ar.indexOf(Item);
        if (index > -1) {
          // only splice array when item is found
          ar.splice(index, 1); // 2nd parameter means remove one item only
        }
        return null; //
      });
    } else {
      SizNumber.map((Item) => {
        const index = ar.indexOf(Item);
        if (index > -1) {
          // only splice array when item is found
          ar.splice(index, 1); // 2nd parameter means remove one item only
        }
        return null; //
      });
    }
  };

  const handelDefaultCategory = (DefaultCategory) => {
    formik.setFieldValue("DefaultCategory", DefaultCategory);
    setCategory(DefaultCategory);
  };

  const onFileSelect = (event) => {
    if (event.target.files[0]) {
      const ImgType = event.target.files[0].type.split("/", 2)[1];
      if (AcceptFileType.includes(ImgType)) {
        formik.setFieldValue("file", event.currentTarget.files[0]);
        setSelectedImg(URL.createObjectURL(event.target.files[0]));
        setSelectedFile(event.target.files);
        if (isEdit || NewItem) {
          formik.setFieldValue("imgUrl", event.target.files[0].name);
          formik.setFieldValue("CountImg", SelectedFile.length);
          formik.setFieldValue("defaultImg", 0);
          formik.setFieldValue("productCode",  event.target.files[0].name.split('.').slice(0, -1).join('.'));
        }
      } else {
        SwToast("error", `😡 file type not include in accept `);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      const ListItemImG = [];
      if (SelectedFile) {
        for (let i = 0; i < SelectedFile.length; i++) {
          if (i <= 9) {
            ListItemImG.push({
              id: i,
              name: SelectedFile[i].name,
              size: SelectedFile[i].size,
              type: SelectedFile[i].type,
              Link: URL.createObjectURL(SelectedFile[i]),
              checked: i === 0 ? true : false,
            });
          }
          formik.setFieldValue("CountImg", SelectedFile.length);
          formik.setFieldValue("defaultImg", 0); // if select 1 file, default
        }
        dispatch(setMultipleImg(ListItemImG));
      }
    }, 200);
    // eslint-disable-next-line
  }, [SelectedFile]);

  const handelRemoveImg = (e, Id) => {
    e.stopPropagation();
    let files = Array.from(SelectedFile); // convert to regular array
    files.splice(Id, 1); // remove item at indexToRemove
    if (SelectedFile.length > 1) {
      setSelectedFile(files);
      if (Id === formik.values.defaultImg && Id !== 0) {
        handelDefaultImg(MultipleImg[0]);
      } else {
        handelDefaultImg(MultipleImg[1]);
      }
    } else {
      setSelectedFile([]);
      setSelectedImg("");
    }
  };

  const handelDefaultImg = (Item) => {
    setSelectedImg(Item.Link);
    dispatch(SelectDefaultImg(Item));
    formik.setFieldValue("imgUrl", Item.name);
    formik.setFieldValue("defaultImg", Item.id);
  };

  useEffect(() => {
    if (SuppliersList.length===1) {
      formik.setFieldValue("Suppliers", SuppliersList[0].SupplierID);
     }
    // eslint-disable-next-line
  }, [SuppliersList]);

  const ImgItem =
    MultipleImg &&
    MultipleImg.map((Item, index) => (
      <div
        className={`Item Transition   ${
          Item.checked === true ? "checked" : ""
        }`}
        key={index}
        onClick={() => handelDefaultImg(Item)}
        style={{ backgroundImage: `url('${Item ? Item.Link : ""}')` }}
      >
        <div
          className="RemoveImg Transition"
          onClick={(e) => handelRemoveImg(e, Item.id)}
        >
          <i className="fa fa-times"></i>{" "}
        </div>
      </div>
    ));
  const SizeNUM = SizNumber.map((Item, index) => (
    <div
      key={index}
      className={`S_NUM ${isSelected(Item) ? "Size-checked" : ""}`}
      onClick={(e) => handleClick(Item, "Num")}
    >
      {Item}
    </div>
  ));

  const SizByXL = SizXL.map((Item, index) => (
    <div
      key={index}
      className={`S_NUM ${isSelected(Item) ? "Size-checked" : ""}`}
      onClick={(e) => handleClick(Item, "letters")}
    >
      {Item}
    </div>
  ));

  const HashTag =
    selectedCategory.length >= 1 &&
    selectedCategory.map((Item, index) => (
      <div
        className={`Hash  ${
          parseInt(Category) === parseInt(Item._id) ? "custom-Hash" : ""
        } `}
        key={Item._id}
        onClick={(e) => handelDefaultCategory(Item._id)}
      >
        #{Item.CatName.trim().replace(" ", "_")}
      </div>
    ));

  useEffect(() => {
    // Placeholder action to sync suppliers list
    dispatch(SyncSuppliers());   
  }, [dispatch]);

  const List = SuppliersList && SuppliersList.length>=1 ? (
    SuppliersList.map((Item, index) => (
      <option key={index} value={Item.SupplierID}>
        {` ${Item.key_Code} -- ${Item.supplier_name}`}
      </option>
    ))
  ) : (
    <></>
  );



  return (
    <div className="EditProduct">
      {isPublished ? (
        <div className="IsLoading"></div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="style16">
            <div
              className={`savForm  ${
                SaveFormProduct === true ? "Checked-true" : ""
              }`}
              onClick={() => handleSaveForm()}
            ></div>
            <button type="submit" className="btn-success">
              {" "}
              حفظ / تحديث{" "}
            </button>
            <h2>
              {!NewItem ? " تحديث منتج " : "منتج جديد"}
              <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            </h2>
          </div>
          <div
            className={`upload-btn-wrapper Transition  `}
            style={{
              backgroundImage: `url('${selectedImg ? selectedImg : BGup}')`,
            }}
          >
            <div className="MultipleIMG_bar">{ImgItem}</div>
            <input
              name="file"
              title=""
              type="file"
              onChange={(event) => onFileSelect(event)}
              accept={AcceptFileType}
              multiple="multiple"
            />
          </div>
          <div className="Suppliers_">
             <select
  name="Suppliers"
  onChange={formik.handleChange}
  onBlur={formik.handleBlur}
  value={FMkv.Suppliers ?? 0} // استخدام عامل Nullish Coalescing لتوفير قيمة افتراضية
  className="round"
  title="المورد"
>
  {List}
</select>
          </div>
          <input
            name="title"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={FMkv.title}
            className="title"
            placeholder="اكتب اسم المنتج  .... "
          /> 
                    

          <div className="ProductPrice">
            <input
              name="price"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.price}
              title="سعر البيع "
              placeholder="بيع   .... "
            />
            <input
              name="Profit"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.Profit}
              title="هامش"
              placeholder="هامش ربح"
            />
            <input
              name="BuyPrice"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.BuyPrice}
              title="شراء"
              placeholder="شراء  .... "
            />

            <input
              className="productCode"
              name="productCode"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.productCode}
              title="كود المنتج"
              style={{ width: "30%", textAlign: "left", paddingLeft: "10px" }}
              placeholder="كود المنتج   .... "
            />
          </div>
          <ActiveCampaign/>
          <div className="product_description">
            <textarea
              name="Description"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={FMkv.Description}
              placeholder="وصف مٌختصر للمنتج ... "
              maxLength={150}
            />
          </div>

          <div className="productSize">{SizeNUM}</div>
          <div className="productSize">{SizByXL}</div>

          {selectedCategory.length > 0 && (
            <div className="Hashtag">
              <div
                className="times-remov Transition"
                onClick={() => dispatch(unSelect(ListCategoriesID))}
              >
                <i className="fa fa-times "></i>
              </div>
              {HashTag}
            </div>
          )}
          <div className="cat-counters">
            <Categories />
          </div>
        </form>
      )}
    </div>
  );
}

export default EditProduct;
