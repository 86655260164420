import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endpoint from "@mongez/http";
import {
  AddToList,
  RemoveEditSelect,
  DeleteSelectedItem,
} from "./selectedSlice";
import { AddToCart } from "./cartSlice";
import cache from "../lib/cache"; //local storage library

export const __ItemOrder = createAsyncThunk(
  "product/ItemOrder",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      return getState().cart.CartList;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ItemOrder = createAsyncThunk(
  "product/ItemOrder",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const products = endpoint
        .post(
          `products/quick_show_order`,
          JSON.stringify({
            UserId: parseInt(getState().auth.UserId),
            OrderById: getState().Orders.IsSelectOrder.Invoice_ID,
          })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });

      return products;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const products = endpoint
        .post(
          `products/default`,
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), Q: _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });

      return products;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//ProductByMcat_
export const Get_ProductBy_Multiple_cat_ = createAsyncThunk(
  "product/Get_ProductBy_Multiple_cat_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      // console.log(_);
      const Item = endpoint
        .post(
          `products/productbymcat_`,
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), cat: _ })
        )
        .then((response) => {
          return { cat: _, items: response.data };
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Item;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const categoryProducts = createAsyncThunk(
  "product/categoryProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      // console.log(_);
      const Item = endpoint
        .post(
          "products/productbycategory_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), cat: _ })
        )
        .then((response) => {
          return { cat: _, items: response.data };
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Item;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
 

export const SupplierProducts = createAsyncThunk(
  "product/SupplierProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      // console.log(_);
      const Item = endpoint
        .post(
          "products/product_by_supplier_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), SupplierID: _ })
        )
        .then((response) => {
          return { cat: _, items: response.data };
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return Item;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const ProductByCode = createAsyncThunk(
  "product/ProductByCode",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultFindByCode = endpoint
        .post("products/find_any_", {
          UserId: parseInt(getState().auth.UserId),
          CategoryID: getState().product.category,
          ProductByCode: _,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultFindByCode;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ShowAllHide = createAsyncThunk(
  "product/ShowAllHide",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultFindByCode = endpoint
        .post("products/show_hide_", {
          UserId: parseInt(getState().auth.UserId),
          CategoryID: getState().product.category,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultFindByCode;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ShowUnUsed = createAsyncThunk(
  "product/ShowUnUsed",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const ResultUnUsed = endpoint
        .post("products/filter_un_used_", {
          UserId: parseInt(getState().auth.UserId),
          ByCat: getState().product.category,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return ResultUnUsed;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const HideProducts = createAsyncThunk(
  "product/HideProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post(
          "products/hide_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Upload_Product_Image = createAsyncThunk(
  "product/Upload_Product_Image",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // .post("products/upload_img",JSON.stringify(_))

      const upload_img = endpoint
        .post("products/upload_img", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return upload_img;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const Save_product_ = createAsyncThunk(
  "product/Save_product_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const save_product = endpoint
        .post(
          "products/save_product_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return save_product;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const LikePost_ = createAsyncThunk(
  "product/LikePost_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("like/like_", {
          userId: parseInt(getState().auth.UserId),
          Pid: _[1],
          ISlike: !_[0],
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const NTrained = createAsyncThunk(
  "product/NTrained",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post(
          "products/n_trained_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTrained_ = createAsyncThunk(
  "product/getTrained_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      const products = endpoint
        .post(
          `products/trained_`,
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), Q: _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });

      return products;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
//products/add_up_product_

export const Save_add_up_product_ = createAsyncThunk(
  "product/Save_add_up_product_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      _.append("UserId", parseInt(getState().auth.UserId));
      const save_product = endpoint
        .post("products/add_up_product_", _)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return save_product;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const setDefaultImg = createAsyncThunk(
  "product/setDefaultImg",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("products/set_def_img_", {
          _,
          UserId: parseInt(getState().auth.UserId),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      // console.log(res );
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const RemoveImg_ = createAsyncThunk(
  "product/RemoveImg_",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post("products/remove_img_", {
          _,
          UserId: parseInt(getState().auth.UserId),
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const RemoveProducts = createAsyncThunk(
  "product/RemoveProducts",
  async (_, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    try {
      endpoint
        .post(
          "products/clear_unu_",
          JSON.stringify({ UserId: parseInt(getState().auth.UserId), _ })
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(`----Error --- > ${error} ----`);
        });
      return _;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// const SavedEdit = cache.get("NewProduct");
const Cash = cache.get("ProductItems");
const initState = {
  unUsed: false,
  products: [],
  BySupplier: [],
  CloneProducts: [],
  SelectEditItem: [],
  MultipleImg: [],
  ProductModal: false,
  isLoading: false,
  isPublished: false,
  ids: [],
  total: 0,
  FilterBy: "ALL",
  OrderBy: "latest",
  CartView: false, //use if i see order detailers
  PriceInfo: true,
  EditProductList: false,
  EditItem: false,
  isEdit: false,
  NewItem: false,
  NewCampaign: false,
  ItemCampaign: false,
  FilterSupplier: false,
  ShowInvoice: true,
  error: null,
  category: 0,
  ProductCode: "",
  DefaultImage: Cash ? Cash.DefaultImage : false || false,
  SearchedProductCode: false,
  ErrorHandling: false,
  SuccessHandling: false,
  ErrorHandlingMsg: null,
  HandlingSuccessMsg: null,
};

const productsSlice = createSlice({
  name: "product",
  initialState: initState,
  reducers: {
    checkedSupplier: (state, action) => {
      const AC = action.payload;
      state.TotalSelect = 0;
      state.ids = [];
      state.supplier_select = AC;
      state.products.forEach((item) => (item.checked = false));
      state.products.filter((item) => item.Suppliers === AC).map(
        (item) => {
          item.checked = true;
          state.ids.push(item.Product_ID);
          state.TotalSelect += 1;
          return item;
        }
      );
    },
    SyncBySupplier: (state, action) => {
      const info = {};
      state.products.forEach((product) => {
        const supplierId = product.Suppliers;//product.supplier_id;
        const tValue = parseInt(product.T_);
        const AMount_ = parseInt(product.T_) * parseInt(product.BuyPrice);
        if (info[supplierId]) {
          info[supplierId].count_item++;
          info[supplierId].T_ = tValue + parseInt(info[supplierId].T_);
          info[supplierId].amount = AMount_ + parseInt(info[supplierId].amount);
          info[supplierId].ids.push(product.id);
        } else {
          info[supplierId] = {
            supplier_id: supplierId,
            supplier_name: product.supplier_name,
            T_: product.T_,
            amount: AMount_,
            count_item: 1,
            checked: false,
            ids: [product.id],
            GroupID: product.GID,
          };
        }
      });
      state.BySupplier = Object.values(info);
    },
    FilterVisible: (state, action) => {
      // state.unUsed=false;
      state.isLoading = true;
      state.products = state.CloneProducts;
      const Res = state.products.filter((result) => {
        return result.Product_Active === action.payload;
      });
      state.products = Res;
      state.isLoading = false;
    },
    FilterVisibleSort: (state, action) => {
      //filter items in order by INorder true
      state.products.sort(function (x, y) {
        return x.Product_Active === y.Product_Active ? 0 : x ? -1 : 1;
      });
    },
    SelectDefaultImg: (state, action) => {
      // state.MultipleImg={...state.MultipleImg,checked:false}
      state.MultipleImg.map((e, index) => (e.checked = false));
      let obj = state.MultipleImg.find((o) => o.id === action.payload.id);
      if (obj) {
        obj.checked = !obj.checked;
      }
    },
    setMultipleImg: (state, action) => {
      state.MultipleImg = [];
      state.MultipleImg = action.payload;
    },
    ONUpdate: (state) => {
      state.EditItem = false;
      state.isEdit = false;
      state.SuccessHandling = false;
      state.ErrorHandlingMsg = null;
      state.HandlingSuccessMsg = null;
      state.SelectEditItem = {
        IndexId: 0,
        id: 0,
        title: "",
        Description: "",
        price: "",
        BuyPrice: "",
        file: {},
        productCode: "",
        imgUrl: "",
        Categories: "",
        Size: "",
        DefaultCategory: "",
      };
    },
    SelectAll: (state, action) => {
      //select all in cart list item
      state.products.map((Item) => (Item.checked = !action.payload));
      // !action.payload? state.isEdit=true: state.isEdit=false;
    },
    ActiveSearch: (state, action) => {
      state.SearchedProductCode = true;
    },
    ActiveProductModal: (state, action) => {
      state.ProductModal = action.payload;
    },
    ChangeCategory: (state, action) => {
      state.unUsed = false;
      state.category = action.payload;
    },
    FindProductByCodeInStore: (state, action) => {
      //search for product in store local
      state.products = state.CloneProducts;
      const Res = state.products.filter((result) => {
        return result.productCode.includes(action.payload);
      });
      state.products = Res;
      state.products.length >= 1
        ? (state.SearchedProductCode = false)
        : (state.SearchedProductCode = true);
    },
    FilterOrder: (state, action) => {
      state.isLoading = false;
      state.OrderBy = action.payload;
          let orderFilter;
          if (action.payload === "lowest") {
        orderFilter = [...state.products].sort((a, b) => a.price - b.price);
      } else if (action.payload === "highest") {
        orderFilter = [...state.products].sort((a, b) => b.price - a.price);
      } else if (action.payload === "BYcode") {
        orderFilter = [...state.products].sort((a, b) => a.productCode.localeCompare(b.productCode));
      } else {
        orderFilter = [...state.products].sort((a, b) => (a.id < b.id ? 1 : -1));
      }
          state.products = orderFilter;
     },
    FilterBySize: (state, action) => {
      state.isLoading = false;
      state.FilterBy = action.payload;
      state.products = state.CloneProducts; // use copy products to add new  query after every sort
      if (action.payload === "ALL") {
        state.products = state.CloneProducts;
      } else {
        let productsFilter = state.products;
        const ProductsBySize = productsFilter.filter(
          (p) => p.sizes.indexOf(state.FilterBy) !== -1
        );
        if (ProductsBySize.length > 0) {
          state.products = ProductsBySize;
        } else {
          state.products = [];
        }
      }
    },

    switchPrice: (state) => {
      state.PriceInfo = !state.PriceInfo;
    },
    switchToEdit: (state, action) => {
      state.EditProductList = action.payload;
      state.NewCampaign = false;
      state.NewItem = false;
      state.ShowInvoice = false;
      state.EditItem = false;
      state.ItemCampaign = false;
      state.FilterSupplier = false;
    },
    isEditItem: (state, action) => {
      state.EditItem = true;
      state.NewItem = false;
      state.NewCampaign = false;
      state.EditProductList = false;
      state.ShowInvoice = false;
      state.ItemCampaign = false;
      state.FilterSupplier = false;

      state.products[action.payload[1]].IndexId = action.payload[1];
      state.SelectEditItem = state.products[action.payload[1]];
      // console.log(state.SelectEditItem);
    },
    isCopyItem: (state, action) => {
      state.EditItem = true;
      state.NewItem = true;
      state.ItemCampaign = false;
      state.NewCampaign = false;
      state.ShowInvoice = false;
      state.EditProductList = false;
      state.FilterSupplier = false;
       state.SelectEditItem = {
        ...state.products[action.payload[1]],
        _id: 0,
        id: 0,
      };
    },
    NewProduct: (state) => {
      state.EditItem = true;
      state.NewItem = true;
      state.ItemCampaign = false;
      state.NewCampaign = false;
      state.ShowInvoice = false;
      state.EditProductList = false;
      state.FilterSupplier = false;

      state.SelectEditItem = cache.get("NewProduct")
        ? cache.get("NewProduct")
        : {
            IndexId: 0,
            id: 0,
            title: "",
            Description: "",
            price: "",
            BuyPrice: "",
            file: {},
            productCode: "",
            imgUrl: "",
            Categories: "",
            Size: "",
            DefaultCategory: "",
          };
     },
    NewCampaign: (state) => {
      state.NewCampaign = true;
      state.NewItem = false;
      state.ShowInvoice = false;
      state.EditItem = false;
      state.EditProductList = false;
      state.ItemCampaign = false;
      state.FilterSupplier = false;
    },
    EditItemCampaign: (state) => {
      state.ItemCampaign = true;
      state.NewCampaign = false;
      state.NewItem = false;
      state.ShowInvoice = false;
      state.EditItem = false;
      state.EditProductList = false;
      state.FilterSupplier = false;
    },
    ShowInvoice: (state, action) => {
      state.ShowInvoice = true;
      state.NewCampaign = false;
      state.NewItem = false;
      state.EditItem = false;
      state.EditProductList = false;
      state.ItemCampaign = false;
      state.FilterSupplier = false;
    },
    FilterBySupplier: (state) => {
      state.FilterSupplier = true;
      state.NewCampaign = false;
      state.NewItem = false;
      state.ShowInvoice = false;
      state.EditItem = false;
      state.EditProductList = false;
      state.ItemCampaign = false;
    },
    ClearSelectedItem: (state, action) => {
      state.isLoading = false;
      const Res = state.products.filter((result) => result.checked === true);
      if (Res.length >= 1) {
        Res.map((result) => (result.checked = false));
      }
    },
  },
  extraReducers: {
    [ShowUnUsed.pending]: (state, action) => {
      state.isLoading = true;
    },
    [ShowUnUsed.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unUsed = false;
      state.total = action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
      state.SearchedProductCode = false;
      state.unUsed = true;
    },
    [ShowUnUsed.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [RemoveImg_.pending]: (state, action) => {},
    [RemoveImg_.fulfilled]: (state, action) => {
      let Res_ = state.products.find((el) => el.id === action.payload[0]);
      if (Res_) {
        Res_.media = Res_.media.filter(
          (el) => el.imgId !== action.payload[1].imgId
        );
        state.ProductModal = Res_;
      }
    },
    [RemoveImg_.rejected]: (state, action) => {
      state.isPublished = true;
    },

    [setDefaultImg.pending]: (state, action) => {},
    [setDefaultImg.fulfilled]: (state, action) => {
      let Res_ = state.products.find((el) => el.id === action.payload[0]);
      if (Res_) {
        Res_.media.map((e, index) => (e.DefaultImage = 0));
        let obj = Res_.media.find((o) => o.imgId === action.payload[1]);
        if (obj) {
          obj.DefaultImage = 1;
          Res_.imgUrl = obj.photo;
          state.ProductModal = Res_;
        }
      }
    },
    [setDefaultImg.rejected]: (state, action) => {
      state.isPublished = true;
    },
    [Save_add_up_product_.pending]: (state, action) => {
      state.isPublished = true;
    },
    [Save_add_up_product_.fulfilled]: (state, action) => {
      state.isPublished = false;

      if (action.payload.ErrorHandling) {
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.ErrorHandling.message;
      } else if (action.payload.success) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.success.message;
        state.ShowInvoice = true;

        if (parseInt(action.payload.Item.IndexId) >= 1) {
          state.products[action.payload.Item.IndexId] = {
            ...action.payload.Item,
          };
        } else {
          // state.products.unshift(action.payload.Item);
          state.products[0] = action.payload.Item;
        }
        state.isPublished = false;
      }
    },
    [Save_add_up_product_.rejected]: (state, action) => {},
    [getTrained_.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTrained_.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
      state.unUsed = false;
    },
    [getTrained_.rejected]: (state, action) => {},
    [LikePost_.pending]: (state, action) => {
      state.error = null;
    },
    [LikePost_.fulfilled]: (state, action) => {},
    [LikePost_.rejected]: (state, action) => {},
    [NTrained.pending]: (state, action) => {
      state.error = null;
    },
    [NTrained.fulfilled]: (state, action) => {
      if (action.payload.listIDs.length >= 1) {
        // console.log(action.payload.listIDs);
        action.payload.listIDs.map((Item, index) => {
          let Res_ = state.products.find((el) => el.id === Item[0]);
          if (Res_) {
            //check if already exists in product list
            Res_.Trained = Item[1]; //checked status of product trend item
            Res_.checked = false;
          }
          state.unUsed = false;
          return null;
        });
      }
    },
    [NTrained.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [Get_ProductBy_Multiple_cat_.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [Get_ProductBy_Multiple_cat_.fulfilled]: (state, action) => {
      state.isLoading = false; //cat       items
      state.products = action.payload.items;
      state.CloneProducts = state.products;
      state.unUsed = false;
    },
    [Get_ProductBy_Multiple_cat_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [Save_product_.pending]: (state) => {
      state.isPublished = true;
      state.error = null;
    },
    [Save_product_.fulfilled]: (state, action) => {
      state.isPublished = false;
      if (action.payload.Error) {
        // console.log(action.payload.Error.message);
        state.ErrorHandling = true;
        state.ErrorHandlingMsg = action.payload.Error.message;
      } else if (action.payload.successful) {
        state.SuccessHandling = true;
        state.HandlingSuccessMsg = action.payload.successful.message;
        state.DefaultImage = false;
      }
    },
    [Save_product_.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [Upload_Product_Image.pending]: (state) => {
      state.error = null;
      state.DefaultImage = "";
    },
    [Upload_Product_Image.fulfilled]: (state, action) => {
      if (action.payload) {
        state.DefaultImage = action.payload[0].imgName;
      }
    },
    [Upload_Product_Image.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [ShowAllHide.pending]: (state) => {
      state.isLoading = true;
    },
    [ShowAllHide.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
      state.SearchedProductCode = false;
      state.unUsed = false;
    },
    [HideProducts.fulfilled]: (state, action) => {
      if (action.payload.states === 1) {
        action.payload.item.map((Item) => {
          state.products = state.products.filter((el) => el.id !== Item);
          return null;
        });
      } else {
        action.payload.item.map((Item) => {
          let Res_ = state.products.find((el) => el.id === Item);
          if (Res_) {
            Res_.checked = false;
          }
          return null;
        });
      }
    },
    [RemoveProducts.fulfilled]: (state, action) => {
      action.payload.item.map((Item) => {
        state.products = state.products.filter((el) => el.id !== Item);
        return null;
      });
    },

    [ProductByCode.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [ProductByCode.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
      state.SearchedProductCode = false;
    },
    [ProductByCode.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [categoryProducts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.SearchedProductCode = false;
      state.unUsed = false;
    },
    [categoryProducts.fulfilled]: (state, action) => {
      state.isLoading = false; //cat       items
      state.total = action.payload.items.length;
      state.products = action.payload.items;
      state.CloneProducts = state.products;
      state.SearchedProductCode = false;
      state.category = action.payload.cat;
    },
    [categoryProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [SupplierProducts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
      state.SearchedProductCode = false;
      state.unUsed = false;
    },
    [SupplierProducts.fulfilled]: (state, action) => {
      state.isLoading = false; //cat       items
      state.unUsed = false;
      state.total = action.payload.items.length;
      state.products = action.payload.items;
      state.CloneProducts = state.products;
      state.SearchedProductCode = false;
      state.unUsed = true;
    },
    [SupplierProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getProducts.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getProducts.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload && action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
    },
    [getProducts.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    /*     [ItemOrder.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [ItemOrder.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.total = action.payload && action.payload.length;
      state.products = action.payload;
      state.CloneProducts = action.payload;
    },
    [ItemOrder.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    }, */
    [AddToList.fulfilled]: (state, action) => {
      //Listen AddToList to set  checked true or false in product list [selected product css]
      state.isLoading = false;
      state.products[action.payload[1]].checked =
        !state.products[action.payload[1]].checked;
    },
    [AddToCart.fulfilled]: (state, action) => {
      // after adding to cart set checked true or false in product list clear selected item css class
      state.isLoading = false;
      if (action.payload[2].Invoice_ID >= 1) {
        //if  already selected order remove checked item css class
        state.products.forEach((e) => {
          e.checked = false;
        });
      }
    },
    [ItemOrder.pending]: (state) => {
      state.isLoading = true;
    },
    [ItemOrder.fulfilled]: (state, action) => {
      // View order item in product section
      state.isLoading = false;
      state.CartView = !state.CartView;
      if (state.CartView) {
        state.CloneProducts = state.products;
        state.total = action.payload && action.payload.length;
        state.products = action.payload;
      } else {
        state.products = state.CloneProducts;
      }
    },
    [ItemOrder.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [RemoveEditSelect.fulfilled]: (state, action) => {
      state.isLoading = false;
      const Res = state.products.filter(
        (result) => result._id === action.payload[0].id
      );
      if (Res.length >= 1) Res[0].checked = false;
    },
    [DeleteSelectedItem.fulfilled]: (state, action) => {
      state.isLoading = false;
      let obj = action.payload.filter((o) => o.checked === true);
      obj.map((Item, index) => {
        let Res = state.products.filter((el) => el.checked);
        if (Res.length !== 0) Res[0].checked = false;
        return null;
      });
    },
  },
});
export const {
  SelectAll,
  setMultipleImg,
  ONUpdate,
  FilterOrder,
  FilterBySize,
  switchPrice,
  ChangeCategory,
  FindProductByCodeInStore,
  ActiveSearch,
  switchToEdit,
  isEditItem,
  ClearSelectedItem,
  NewProduct,
  ShowInvoice,
  NewCampaign,
  EditItemCampaign,
  FilterBySupplier,
  checkedSupplier,
  SelectDefaultImg,
  ActiveProductModal,
  FilterVisible,
  isCopyItem,
  SyncBySupplier,
} = productsSlice.actions;
export default productsSlice.reducer;
