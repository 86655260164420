import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterVisible,FilterSelected,
  SubmitINorder,
  DelSelectedItem_with_hide,
  DeleteSelectedItem,
  HideSelectItem,
  Sync_NotInOrders,handleFilterUpDwn,
  SelectAll,
} from "../../../store/NotInOrdersSlice";
import { switchPrice } from "../../../store/productsSlice";
import { SyncTelegramGroups } from "../../../store/TelegramSlice";
import GroupsTelegram from "./GroupsTelegram";
import Swal from "sweetalert2";
import "../../../assets/css/View_Catalog/Components/ToolBar.css";
import { words } from "../../../words";
import FindItem from "./FindItem";
const LNG = words.lang.ar.NotInOrders;
const btn = LNG.HToolBar;
const lAmsg = btn.alertMessage;
const lmsg = btn.SwalMsg;
const lmsgh = btn.SwalMsghide;
const Lfier = btn.Swal_fire;

function ToolBar(props) {
  const { handleZoom } = props;
  const { ItemProduct, TotalSelect, ids } = useSelector(
    (state) => state.NotInOrders
  );
  const [IsSelected, setIsSelected] = useState(false);
  const [Visible, setVisible] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
/*   const handlePrint = () => {
    window.print();
  }; */
  const handleBack = (path) => {
    navigate(path);
  };
  const handleSubmit = () => {
    dispatch(SubmitINorder(ids));
  };

  const handleSync = () => {
    dispatch(Sync_NotInOrders());
    dispatch(SyncTelegramGroups());
  };
  const handleSelectAll = () => {
    setIsSelected(!IsSelected);
    dispatch(SelectAll(IsSelected));
  };

  const handleDelete = (path) => {
    if (ids.length > 0) {
      Swal.fire({
        title: lmsg.title,
        text: lmsg.text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: lmsg.cancelButtonText,
        confirmButtonText: lmsg.confirmButtonText,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(DeleteSelectedItem(ids));
          Swal.fire(Lfier.title, Lfier.text, "success");
        }
      });
    } else {
      return alertMessage();
    }
  };

  const handleDeleteWitheHide_ = (path) => {
    if (ids.length > 0) {
      Swal.fire({
        title: lmsgh.title,
        text: lmsgh.text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: lmsgh.cancelButtonText,
        confirmButtonText: lmsgh.confirmButtonText,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(DelSelectedItem_with_hide(ids));
          Swal.fire(lmsgh.title, lmsgh.text, "success");
        }
      });
    } else {
      return alertMessage();
    }
  };

  const alertMessage = (e) => {
    let timerInterval;
    Swal.fire({
      title: lAmsg.title,
      html: lAmsg.text,
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    });
  };

  const handleVisible = () => {
    setVisible(!Visible);
    dispatch(FilterVisible(Visible ? 1 : 0));
  };

  const handleFilterSelected = () => {
    setVisible(!Visible);
  dispatch(FilterSelected((Visible ? 1 : 0)))
  };
const handleUpDwn = () => {
  setVisible(!Visible);
  dispatch(handleFilterUpDwn((Visible ? 1 : 0)))
 }
  return (
    <div className="NavControls">
      <div className="Nav-options">
        <div
          className="AtoCalc Transition BTN_"
          rel=""
          onClick={() => handleBack("/cashier")}
          title={btn.button_1}
        >
          <i className="fa fa-cash-register "></i>
        </div>

        <div
          className="AtoCalc Transition BTN_"
          rel=""
          onClick={() => handleZoom()}
          title={btn.button_10}
        >
          <i className="fas fa-search-plus"></i>
        </div>
        
        <div
          className="AtoCalc Transition BTN_"
          title="عرض غير متوفر"
          onClick={() => handleVisible()}
        >
          <i className="fa fa-sort-amount-down "></i>
        </div>

        <div
          className="AtoCalc Transition BTN_"
          title="فرز تبديل"
          onClick={() => handleUpDwn()}
        >
          <i className="fa fa-sort "></i>
          </div>

        <div
          className="AtoCalc Transition BTN_"
          title="فرز للاعلى"
          onClick={() => handleFilterSelected()}
        >
          <i className="fa fa-sort-amount-down "></i>
        </div>

{ids.length >= 1 && (<><div className="AtoCalc Transition BTN_" rel="" title={btn.button_6} onClick={() => dispatch(HideSelectItem())}><i className="fa fa-eye "></i></div></>)}
<div
className="AtoCalc Transition BTN_"
onClick={() => dispatch(switchPrice())}
>
<i className="fa fa-ticket-alt "></i>{" "}
</div>
 <div style={{ border: "1px dashed rgb(53 170 243)" }} title={btn.button_2} className={`AtoCalc Transition BTN_ Transition Check-inOrder 
 ${ IsSelected ? "Checked-true" : "" }`}  onClick={() => handleSelectAll()} ></div> 

  <div  className="AtoCalc Transition BTN_"   id="TotalSelProducts"      title={btn.button_3}     > {TotalSelect}        </div>
  <div className="AtoCalc Transition BTN_" id="TotalSelProducts" title={btn.button_4} >  {ItemProduct.length}  </div>
{/* <div  className="AtoCalc Transition BTN_" rel="" style={{ color: "#8f1eb4" }} title={btn.button_5} onClick={() => handlePrint()}  > <i className="fa fa-print "></i> </div>
 */}        {ids.length >= 1 && (
          <>
    
            <div
              className="AtoCalc Transition BTN_"
              rel=""
              title={btn.button_8}
              onClick={() => handleDelete()}
            >
              <i className="fa fa-trash "></i>
            </div>
            <div
              className="AtoCalc Transition BTN_"
              rel=""
              title={btn.button_9}
              style={{ color: "#ffb22be8" }}
              onClick={() => handleDeleteWitheHide_()}
            >
              <i className="fa fa-trash"></i>
            </div>
          </>
        )}

        <GroupsTelegram />

        <FindItem />
        <div
          className="AtoCalc Transition BTN_"
          rel=""
          onClick={() => handleSync()}
          title={btn.button_10}
        >
          <i className="fa fa-sync "></i>
        </div>
        {ids.length >= 1 && ( <> <div className="AtoCalc Transition BTN_" rel="" title={btn.button_7} 
 onClick={() => handleSubmit()} > <i className="fa fa-check "></i> </div> </> )}
      </div>
    </div>
  );
}

export default ToolBar;
